import React, { useState, useRef, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  TextField,
  Paper,
  Chip,
  CircularProgress,
  InputAdornment,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import axios from 'axios';
import useUserProfile from '../hooks/useUserProfile';
import SupplementsTable from './SupplementsTable';
import config from '../config';
import '../common.css';
import { useTheme } from '@mui/material/styles';
import { calculateAge } from '../Utils';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import EditIcon from '@mui/icons-material/Edit';
import { buttonStyles } from './common/ButtonStyles';
import StackCreationForm from './common/StackCreationForm';
import { fetchConditions, fetchSupplements } from '../Utils';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const { BASE_URL, MAX_REQUESTS } = config;

function CreateStack() {
  const [stackName, setStackName] = useState('');
  const [healthGoals, setHealthGoals] = useState('');
  const [selectedSupplements, setSelectedSupplements] = useState([]);
  const [loadingAI, setLoadingAI] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showStackDetails, setShowStackDetails] = useState(false);
  const [notes, setNotes] = useState('');
  const { userProfile } = useUserProfile();
  const navigate = useNavigate();
  const theme = useTheme();
  const [isInitialized, setIsInitialized] = useState(false);
  const [savedScrollPosition, setSavedScrollPosition] = useState(0);
  const [useResearch, setUseResearch] = useState(() => {
    return sessionStorage.getItem('useResearch') === 'true';
  });
  const [supplements, setSupplements] = useState([]);
  const [conditions, setConditions] = useState([]);

  // Define getStackId at the top level of the component
  const getStackId = () => {
    const params = new URLSearchParams(window.location.search);
    let stackId = params.get('tempId');
    
    // If no tempId in URL, check sessionStorage
    if (!stackId) {
      stackId = sessionStorage.getItem('currentStackTempId');
      
      // If we found an ID in sessionStorage, update the URL
      if (stackId) {
        params.set('tempId', stackId);
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        window.history.replaceState({}, '', newUrl);
      }
    }
    
    // If still no tempId, generate a new one
    if (!stackId) {
      stackId = 'temp_' + Date.now();
      params.set('tempId', stackId);
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      window.history.replaceState({}, '', newUrl);
    }
    
    // Always keep the current tempId in sessionStorage
    sessionStorage.setItem('currentStackTempId', stackId);
    return stackId;
  };

  // Ref for scrolling to stack details
  const stackDetailsRef = useRef(null);

  // Add initialization effect
  useEffect(() => {
    const initializeStack = async () => {
      const currentUrl = window.location.href;
      const urlParams = new URL(currentUrl);
      const source = urlParams.searchParams.get('source');
      const shouldGenerate = sessionStorage.getItem('shouldGenerateStack');
      const pendingGoals = sessionStorage.getItem('pendingHealthGoals');
      
      // Restore scroll position
      const savedPosition = sessionStorage.getItem('stackScrollPosition');
      if (savedPosition) {
        window.scrollTo(0, parseInt(savedPosition));
        sessionStorage.removeItem('stackScrollPosition');
      }

      if (!isInitialized && source === 'landing' && shouldGenerate === 'true' && pendingGoals) {
        setHealthGoals(pendingGoals);
        setIsInitialized(true);
        
        sessionStorage.removeItem('pendingHealthGoals');
        sessionStorage.removeItem('shouldGenerateStack');
        
        await new Promise(resolve => setTimeout(resolve, 0));
        await handleGenerateStack(pendingGoals);
      }
    };

    initializeStack();
  }, [isInitialized]);

  // Add effect to save research toggle state
  useEffect(() => {
    sessionStorage.setItem('useResearch', useResearch);
  }, [useResearch]);

  const sampleGoals = [
    'Weight Management',
    'Muscle Health',
    'Digestion ',
    'Energy ',
    'Sleep Hygiene',
    'Energy ',
    'Heart Health',
    'Focus',
    'Stress Relief ',
    'Immunity',
    'Healthy Hair & Skin ',
    'Healthy Aging ',
    'Memory ',
    'Joint Health',
    'Bone Health ',
    'Hormone Balance ',
    'Prostate Health ',
    'Eye Health',
    'Prenatal & Postnatal',
    'Healthy Mood',
    'Gluclose Metabolism '
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    const auth = getAuth();
    const user = auth.currentUser;

    if (!stackName) {
      setErrorMessage('Stack name is required.');
      return;
    }

    if (user) {
      try {
        // Create health goals document
        const healthGoalDocRef = await addDoc(collection(db, 'healthGoals'), {
          goals: healthGoals,
          userId: user.uid
        });

        // Create notes document
        const notesDocRef = await addDoc(collection(db, 'notes'), {
          content: notes,
          userId: user.uid
        });

        // Create stack document with references to health goals and notes
        const stack = {
          name: stackName,
          userId: user.uid,
          supplements: selectedSupplements,
          healthGoalsRef: healthGoalDocRef.id,
          notesRef: notesDocRef.id,
          origin: 'AI'
        };

        await addDoc(collection(db, 'stacks'), stack);

        // Clear the saved state after successful submission
        const params = new URLSearchParams(window.location.search);
        const tempId = params.get('tempId');
        if (tempId) {
          localStorage.removeItem(`unsavedStack_${tempId}`);
        }

        navigate('/dashboard');
      } catch (error) {
        setErrorMessage('Error creating stack: ' + error.message);
      }
    } else {
      setErrorMessage('You need to login to create a stack.');
      navigate('/login');
    }
  };

  const [showRequestLimitModal, setShowRequestLimitModal] = useState(false);

  const handleGenerateStack = async (passedGoals = null) => {
    // Get the current request count from localStorage (or default to 0 if not set)
    let requestCount = parseInt(localStorage.getItem('stackRequestCount')) || 0;
  
    // Clear previous stack details
    setStackName('');
    setSelectedSupplements([]);
    setShowStackDetails(false);
    setNotes('');
  
    setLoadingAI(true);
    const auth = getAuth();
    const user = auth.currentUser;
  
    // Declare variables for stack generation
    let token = '';
    let calculatedAge = 'unknown';
    let gender = 'unknown';
  
    try {
      if (user) {
        token = await auth.currentUser.getIdToken();
        calculatedAge = calculateAge(userProfile.dateOfBirth);
        gender = userProfile.gender;
      }
      else {
        if (requestCount >= MAX_REQUESTS) {
          setShowRequestLimitModal(true);
          return;
        }
      }
  
      // Use passed goals if available, otherwise use state
      const goalsToUse = passedGoals || healthGoals;
      
      // Endpoint options:
      // - /generate-stack : Basic stack generation
      // - /generate-research-stack : Research-based stack generation
      const response = await axios.post(`${BASE_URL}/generate-research-stack`, {
        healthGoals: goalsToUse,
        age: calculatedAge,
        gender: gender
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      // Set the AI-generated stack data
      setStackName(response.data.name);
      setSelectedSupplements(response.data.supplements);
      setShowStackDetails(true);
  
      // Generate tempId if not exists
      const params = new URLSearchParams(window.location.search);
      if (!params.get('tempId')) {
        const tempId = 'temp_' + Date.now();
        params.set('tempId', tempId);
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        window.history.replaceState({}, '', newUrl);
      }
  
      // Scroll to the stack details section
      setTimeout(() => {
        stackDetailsRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 0);
  
      // Increment the request count and store it back in localStorage
      requestCount++;
      localStorage.setItem('stackRequestCount', requestCount);
  
    } catch (error) {
      
    } finally {
      setLoadingAI(false);
    }
  };
  const toggleRowExpansion = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const toggleEditMode = (index) => {
    setEditMode(editMode === index ? null : index);
    if (editMode !== index) {
      setExpandedRow(index);
    }
  };

  const handleSampleGoalClick = (goal) => {
    const currentGoals = healthGoals ? healthGoals.split(',').map(g => g.trim()) : [];
    if (!currentGoals.includes(goal)) {
      const newGoals = [...currentGoals, goal].join(', ');
      setHealthGoals(newGoals);
    }
  };
 

  // Add this effect to check for returning stack state
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tempId = params.get('tempId');
    
    if (tempId) {
      const savedStack = localStorage.getItem(`unsavedStack_${tempId}`);
      if (savedStack) {
        try {
          const { 
            supplements, 
            stackName: savedName, 
            healthGoals: savedGoals,
            notes: savedNotes, 
            timestamp 
          } = JSON.parse(savedStack);
          
          const oneHour = 60 * 60 * 1000;
          if (new Date().getTime() - timestamp < oneHour) {
            if (supplements) setSelectedSupplements(supplements);
            if (savedName) setStackName(savedName);
            if (savedGoals) setHealthGoals(savedGoals);
            if (savedNotes) setNotes(savedNotes);
            setShowStackDetails(true);
            
            // Clear tempId from URL without page reload
            const newUrl = new URL(window.location.href);
            newUrl.searchParams.delete('tempId');
            window.history.replaceState({}, '', newUrl);
          }
        } catch (error) {
          
        }
      }
    }
  }, []); // Run once on component mount

  // Modify handleArticleClick to save scroll position
  const handleArticleClick = (supplementId, e) => {
    e.preventDefault();
    if (!supplementId) return;

    const stackId = getStackId();
    
    // Save scroll position
    const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
    sessionStorage.setItem('stackScrollPosition', currentScroll.toString());
    
    // Save current stack state with all form data
    const stackState = {
      supplements: selectedSupplements,
      stackName: document.querySelector('input[type="text"]')?.value || '',
      healthGoals: document.querySelector('textarea[placeholder*="health goals"]')?.value || '',
      notes: notes,
      timestamp: new Date().getTime()
    };
    
    localStorage.setItem(`unsavedStack_${stackId}`, JSON.stringify(stackState));
    window.location.href = `/supplements/${supplementId}?tempId=${stackId}`;
  };

  // Add effect specifically for scroll position restoration
  useEffect(() => {
    const restoreScrollPosition = () => {
      const savedPosition = sessionStorage.getItem('stackScrollPosition');
      
      if (savedPosition) {
        
        // Add a longer delay and ensure content is loaded
        setTimeout(() => {
          
          window.scrollTo({
            top: parseInt(savedPosition),
            behavior: 'instant'
          });
        
          // Clear the saved position after restoration
          sessionStorage.removeItem('stackScrollPosition');
        }, 500); // Increased delay to 500ms
      } else {
        
      }
    };

    // Only restore if stack details are shown
    if (showStackDetails) {
      restoreScrollPosition();
    } else {
    }
  }, [showStackDetails, selectedSupplements]); // Added selectedSupplements as dependency

  // Add logging for tempId at component mount
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tempId = params.get('tempId');    
    // Log any saved state for this tempId
    if (tempId) {
      const savedState = localStorage.getItem(`unsavedStack_${tempId}`);
      
    }
  }, []);

  // Modify scroll restoration effect
  useEffect(() => {
    const restoreScrollPosition = () => {
      const stackId = getStackId();
      const savedStackState = localStorage.getItem(`unsavedStack_${stackId}`);
      
      if (savedStackState) {
        try {
          const state = JSON.parse(savedStackState);
          if (state.scrollPosition) {
            // Multiple restoration attempts to ensure content is loaded
            [100, 300, 500].forEach(delay => {
              setTimeout(() => {
                window.scrollTo({
                  top: parseInt(state.scrollPosition),
                  behavior: 'instant'
                });
              }, delay);
            });
          }
        } catch (error) {
          
        }
      }
    };

    if (showStackDetails) {
      restoreScrollPosition();
    }
  }, [showStackDetails]);

  // Ensure URL has tempId
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (!params.get('tempId')) {
      const stackId = getStackId();
      params.set('tempId', stackId);
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      window.history.replaceState({}, '', newUrl);
    }
  }, []);

  // Add effect to load data
  useEffect(() => {
    const loadData = async () => {
      try {
        const [supplementsList, conditionsList] = await Promise.all([
          fetchSupplements(config),
          fetchConditions(config)
        ]);
        
        setSupplements(supplementsList);
        setConditions(conditionsList);
      } catch (error) {
        console.error('Error loading data:', error);
      }
    };

    loadData();
  }, []);

  return (
    <Container component="main" maxWidth="md">
      <Paper 
        elevation={0} 
        sx={{ 
          padding: { xs: 2, sm: 2.5 },
          marginTop: 1,
          marginBottom: 2,
          borderRadius: '16px',
          background: '#fff',
          boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          gap: 1.5 
        }}>
          <Box sx={{ width: '100%', maxWidth: '500px' }}>
            <StackCreationForm
              healthGoals={healthGoals}
              setHealthGoals={setHealthGoals}
              onSubmit={handleGenerateStack}
              loading={loadingAI}
              variant="full"
              showHeader={true}
              useResearch={useResearch}
              onResearchToggle={setUseResearch}
            />
          </Box>
        </Box>
      </Paper>

      {/* Stack Results Section */}
      {showStackDetails && (
        <Box ref={stackDetailsRef} sx={{ mt: 3 }}>
          {/* Stack Name Section */}
          <Paper
            elevation={0}
            sx={{
              mb: 2,
              p: { xs: 2, sm: 2.5 },
              borderRadius: '16px',
              background: '#fff',
              boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: 1,
              flexWrap: 'wrap'  // Allows wrapping on mobile
            }}>
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ minWidth: 'fit-content' }}
              >
                Stack Name:
              </Typography>
              <TextField
                value={stackName}
                onChange={(e) => setStackName(e.target.value)}
                variant="standard"
                fullWidth
                sx={{
                  maxWidth: { xs: '100%', sm: '400px' },
                  '& .MuiInput-root': {
                    fontSize: '1rem',
                    fontWeight: 400,
                    '&:before': {
                      borderBottom: '2px solid #E2E8F0'
                    },
                    '&:hover:not(.Mui-disabled):before': {
                      borderBottom: '2px solid #CBD5E0'
                    },
                    '&.Mui-focused:after': {
                      borderBottom: '2px solid #4299E1'
                    }
                  },
                  '& .MuiInput-input': {
                    padding: '4px 0'
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        size="small"
                        onClick={() => {
                          const input = document.querySelector('input[value="' + stackName + '"]');
                          if (input) {
                            input.focus();
                            input.select();
                          }
                        }}
                        sx={{ 
                          color: '#A0AEC0',
                          padding: '4px',
                          '&:hover': {
                            color: '#4A5568'
                          }
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
          </Paper>

          <SupplementsTable
            selectedSupplements={selectedSupplements}
            setSelectedSupplements={setSelectedSupplements}
            notes={notes}
            setNotes={setNotes}
            getStackId={getStackId}
            supplements={supplements}
          />
          
          {/* Notes and Save Section */}
          <Paper
            elevation={0}
            sx={{
              mt: 2,
              p: { xs: 2, sm: 2.5 },
              borderRadius: '16px',
              background: '#fff',
              boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
            }}
          >
            <TextField
              fullWidth
              multiline
              rows={3}
              placeholder="Add notes about your stack (optional)"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              variant="outlined"
              size="small"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  backgroundColor: '#F7FAFC',
                  '&:hover': {
                    backgroundColor: '#EDF2F7',
                  },
                  '&.Mui-focused': {
                    backgroundColor: '#F7FAFC',
                    boxShadow: '0 0 0 2px rgba(66, 153, 225, 0.2)',
                  }
                }
              }}
            />

            <Box sx={{ 
              mt: 2,
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2
            }}>
              <Button
                variant="outlined"
                onClick={() => navigate('/dashboard')}
                sx={buttonStyles.secondary}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={buttonStyles.primary}
              >
                Save Stack
              </Button>
            </Box>
          </Paper>
          

        </Box>
      )}

      <Dialog 
        open={showRequestLimitModal} 
        onClose={() => setShowRequestLimitModal(false)}
        PaperProps={{
          sx: {
            borderRadius: '16px',
            maxWidth: '400px',
            mx: 2
          }
        }}
      >
        <DialogTitle sx={{ 
          pb: 1,
          color: '#435334',
          fontWeight: 600 
        }}>
          Maximum Recommendations Reached
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 2 }}>
            You've reached the maximum number of stack recommendations. Please log in to continue using myStack.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2.5, pt: 1.5 }}>
          <Button 
            onClick={() => {
              setShowRequestLimitModal(false);
              navigate('/login');
            }} 
            variant="contained"
            sx={buttonStyles.primary}
          >
            Log In
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default CreateStack;
                     