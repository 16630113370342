import React from 'react';
import { 
  TextField, 
  Box, 
  Button, 
  Typography, 
  Chip, 
  InputAdornment, 
  IconButton, 
  CircularProgress, 
  Alert, 
  Collapse, 
  Switch,
  Tooltip,
  FormControlLabel
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { buttonStyles } from './ButtonStyles';
import { useState, useEffect } from 'react';
import { keyframes } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const sampleGoals = [
  'Weight Management',
  'Muscle Health',
  'Digestion',
  'Energy',
  'Sleep Hygiene',
  'Heart Health',
  'Focus',
  'Stress Relief',
  'Immunity',
  'Healthy Hair & Skin',
  'Healthy Aging',
  'Memory',
  'Joint Health',
  'Bone Health',
  'Prostate Health',
  'Eye Health',
  'Prenatal & Postnatal',
  'Healthy Mood',
  'Gluclose Metabolism'
];

const shimmer = keyframes`
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
`;

const disabledStripes = keyframes`
  from { background-position: 0 0; }
  to { background-position: 10px 0; }
`;

export default function StackCreationForm({ 
  healthGoals, 
  setHealthGoals, 
  onSubmit, 
  loading = false,
  variant = 'full', // 'full' or 'landing'
  showHeader = true,
}) {
  const [showError, setShowError] = useState(false);
  const [showDisclaimerBanner, setShowDisclaimerBanner] = useState(false);

  useEffect(() => {
    // Show disclaimer banner when user types something and we're on landing page
    // Hide it when the field is emptied
    if (variant === 'landing') {
      setShowDisclaimerBanner(healthGoals.trim() ? true : false);
    }
  }, [healthGoals, variant]);

  const handleSampleGoalClick = (goal) => {
    const currentGoals = healthGoals ? healthGoals.split(',').map(g => g.trim()) : [];
    if (!currentGoals.includes(goal)) {
      const newGoals = [...currentGoals, goal].join(', ');
      setHealthGoals(newGoals);
    }
  };

  const handleButtonClick = () => {
    if (!healthGoals.trim()) {
      setShowError(true);
      setTimeout(() => setShowError(false), 3000);
    } else {
      setShowError(false);
      onSubmit();
    }
  };

  // Hide the permanent disclaimer when on landing page (we'll use collapsible banner instead)
  const shouldShowPermanentDisclaimer = variant !== 'landing';

  return (
    <Box sx={{ 
      width: '100%', 
      maxWidth: '500px',
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: -2,
        left: -2,
        right: -2,
        bottom: -2,
        background: 'linear-gradient(135deg, #9EB384 0%, #435334 100%)',
        opacity: 0.5,
        borderRadius: '22px',
        filter: 'blur(8px)',
        transition: 'opacity 0.3s ease',
      },
      '&:hover::before': {
        opacity: 0.7,
      }
    }}>
      <Box sx={{ 
        position: 'relative', 
        zIndex: 1,
        p: 3,
        backgroundColor: '#fff',
        borderRadius: '20px',
      }}>
        {showHeader && (
          <>
            <Typography
              component="h1"
              variant="h5"
              sx={{ 
                fontFamily: 'Lora, serif',
                fontWeight: 700,
                fontSize: '24px',
                letterSpacing: '0.1px',
                background: 'linear-gradient(90deg, #9EB384 15%, #435334 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                textShadow: '0 1px 2px rgba(0,0,0,0.1)',
                padding: '4px 0',
                mb: 0,
                textAlign: 'center',
                transition: 'transform 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-1px)',
                }
              }}
            >
              myStack AI
            </Typography>
            
            <Typography 
              variant="body2"
              color="text.secondary"
              textAlign="center"
              sx={{ 
                maxWidth: '400px',
                lineHeight: 1.2,
                mb: 3,
                mx: 'auto',
                fontSize: '.8rem',
                fontWeight: 400,
                letterSpacing: '0.1px',
                fontFamily: 'Lora, serif',
              }}
            >
              Let myStack AI analyze your health goals and recommend a personalized supplement stack
            </Typography>
          </>
        )}

        <TextField
          fullWidth
          multiline
          rows={2}
          placeholder="What are your health goals? Type or select from quick adds..."
          value={healthGoals}
          onChange={(e) => setHealthGoals(e.target.value)}
          variant="outlined"
          size="small"
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '12px',
              backgroundColor: '#F7FAFC',
              fontSize: '0.75rem',
              transition: 'all 0.2s ease',
              '&:hover': {
                backgroundColor: '#EDF2F7',
                transform: 'translateY(-1px)',
              },
              '&.Mui-focused': {
                backgroundColor: '#F7FAFC',
                boxShadow: '0 0 0 2px rgba(158, 179, 132, 0.3)',
                transform: 'translateY(-1px)',
              }
            }
          }}
          InputProps={{
            endAdornment: healthGoals && (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setHealthGoals('')}
                  edge="end"
                  size="small"
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        
        <Box sx={{ mt: 2 }}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            mb: 1
          }}>
            <Typography variant="caption" color="text.secondary">
              Quick adds:
            </Typography>

            {/* Research toggle UI code has been removed. 
                To restore it, check git history or refer to previous versions 
                where the "Use Recent Research" toggle was implemented. */}
          </Box>

          <Box 
            className="scrollable-chips" 
            sx={{ 
              mb: 2,
              display: 'flex',
              flexWrap: 'wrap',
              gap: 0.5,
              maxHeight: '60px',
              overflowY: 'auto',
              pb: 0.5,
              position: 'relative',
              backgroundColor: '#F7FAFC',
              border: '1px solid #E2E8F0',
              borderRadius: '8px',
              padding: '4px',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: '12px',
                background: 'linear-gradient(to top, rgba(0,0,0,0.05) 0%, transparent 100%)',
                pointerEvents: 'none',
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',
              },
              '&::-webkit-scrollbar': {
                width: '4px',
                backgroundColor: 'transparent',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(24, 80, 2, 0.2)',
                borderRadius: '2px',
                '&:hover': {
                  backgroundColor: 'rgba(24, 80, 2, 0.3)',
                }
              }
            }}
          >
            {sampleGoals.map((goal, index) => (
              <Chip
                key={index}
                label={goal}
                onClick={() => handleSampleGoalClick(goal)}
                size="small"
                sx={{
                  borderRadius: '6px',
                  height: '24px',
                  fontSize: '0.68rem',
                  backgroundColor: '#fff',
                  border: '1px solid #E2E8F0',
                  color: '#334155',
                  '&:hover': {
                    backgroundColor: '#EDF2F7',
                    borderColor: '#CBD5E0',
                    color: '#334155',
                  },
                }}
              />
            ))}
          </Box>
        </Box>

        <Collapse in={showError}>
          <Alert 
            severity="info" 
            sx={{ 
              mt: 2,
              borderRadius: '8px',
              '& .MuiAlert-icon': {
                color: '#435334'
              }
            }}
          >
            Please enter your health goals or select from the suggestions above
          </Alert>
        </Collapse>

        <Box sx={{ mt: 2}}>
          <Box sx={{ display: 'block' , mb: 2}}>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              flexWrap: 'wrap', // Allow wrapping on small screens
              gap: 1 // Add consistent spacing
            }}>
              {/* Research toggle UI code has been removed. 
                  To restore it, check git history or refer to previous versions 
                  where the "Use Recent Research" toggle was implemented. */}
            </Box>
          </Box>

          <Button
            variant="contained"
            color="primary"
            onClick={handleButtonClick}
            disabled={loading}
            sx={{
              mt: 2,
              ...buttonStyles.primary,
              position: 'relative',
              overflow: 'hidden',
              margin: '16px auto 0',
              display: 'block',
              width: 250,
              '&::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent)',
                transform: 'translateX(-100%)',
                transition: 'transform 0.3s ease',
              },
              '&:hover::after': {
                transform: 'translateX(100%)',
              },
              animation: !healthGoals.trim() ? `${shimmer} 2s infinite` : 'none'
            }}
          >
            {loading ? (
              <Box sx={buttonStyles.iconWrapper}>
                <CircularProgress size={16} color="inherit" />
                <span>Analyzing...</span>
              </Box>
            ) : (
              <Typography sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                gap: '5px',
                fontSize: '0.95rem',
                fontWeight: 500
              }}>
                <AutoFixHighIcon fontSize="small" />
                Recommend Stack
              </Typography>
            )}
          </Button>
          
          {/* Collapsible disclaimer banner for landing page - moved below button */}
          {variant === 'landing' && (
            <Collapse in={showDisclaimerBanner}>
              <Box sx={{ 
                mt: 2,
                border: '1px solid #9EB384',
                borderRadius: '8px',
                p: 0.75,
                backgroundColor: 'rgba(158, 179, 132, 0.08)',
                display: 'flex', 
                alignItems: 'flex-start',
                width: '100%',
              }}>
                <InfoOutlinedIcon sx={{ 
                  fontSize: '0.9rem', 
                  mr: 0.75, 
                  color: '#435334',
                  mt: '1px'
                }} />
                <Typography variant="caption" sx={{ 
                  fontSize: '0.68rem', 
                  lineHeight: 1.3,
                  color: '#333333',
                  fontWeight: 500
                }}>
                  This product is not intended to diagnose, treat, cure, or prevent any disease. Please consult your healthcare provider before taking any supplements.
                </Typography>
              </Box>
            </Collapse>
          )}
          
          {/* Only show permanent disclaimer when not on landing page */}
          {shouldShowPermanentDisclaimer && (
            <Box sx={{ 
              mt: 1.5,
              border: '1px solid #9EB384',
              borderRadius: '8px',
              p: 0.75,
              backgroundColor: 'rgba(158, 179, 132, 0.08)',
              display: 'flex', 
              alignItems: 'flex-start',
              width: '100%',
            }}>
              <InfoOutlinedIcon sx={{ 
                fontSize: '0.9rem', 
                mr: 0.75, 
                color: '#435334',
                mt: '1px'
              }} />
              <Typography variant="caption" sx={{ 
                fontSize: '0.68rem', 
                lineHeight: 1.3,
                color: '#333333',
                fontWeight: 500
              }}>
                 This product is not intended to diagnose, treat, cure, or prevent any disease. Please consult your healthcare provider before taking any supplements.
              </Typography>
            </Box>
          )}
        </Box>
        
        {variant === 'landing' && (
          <Typography 
            variant="caption" 
            color="text.secondary"
            sx={{ 
              display: 'block', 
              textAlign: 'center',
              mt: 2,
              opacity: 0.8
            }}
          >
          Get instant Supplement suggestions with research citations
          </Typography>
        )}
      </Box>
    </Box>
  );
} 